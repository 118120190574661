const View = () => import(/* webpackChunkName: "dashboard" */ '../../pages/Layout/View.vue');
// Calendar
const RegularForms = () => import('src/pages/Forms/RegularForms.vue');
const ExtendedForms = () => import('src/pages/Forms/ExtendedForms.vue');
const ValidationForms = () => import('src/pages/Forms/ValidationForms.vue');
const Wizard = () => import('src/pages/Forms/Wizard.vue');

export default {
    path: '/forms',
    redirect: '/forms/regular',
    component: View,
    name: 'forms',
    children: [
      {
        path: 'regular',
        name: 'Regular Forms',
        components: { default: RegularForms }
      },
      {
        path: 'extended',
        name: 'Extended Forms',
        components: { default: ExtendedForms }
      },
      {
        path: 'validation',
        name: 'Validation Forms',
        components: { default: ValidationForms }
      },
      {
        path: 'wizard',
        name: 'Wizard',
        components: { default: Wizard }
      }
    ]
}