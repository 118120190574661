import AboutYouLayout from 'src/pages/Pages/AboutYouLayout.vue';
const UserInfo = () =>
  import(/* webpackChunkName: "pages" */ 'src/pages/Pages/Register/UserInfo.vue');

const Organization = () =>
  import(/* webpackChunkName: "pages" */ 'src/pages/Pages/Register/Organization.vue');


export default {
    path: '/on-board',
    component: AboutYouLayout,
    //name: 'Aboutuser',
    redirect: '/on-board/organization',
    children: [
      {
        path: 'whats-next',
        name: 'AboutUser',
        meta: {
          title: 'About you'
        },
        component: UserInfo
      },
      {
        path: 'organization',
        name: 'UserOrganization',
        meta: {
          title: 'Lets get to know each toehr'
        },
        component: Organization
      },
    ],
    meta: {
      requiresAuth: true
    }
}