<template>
  <div
    class="form-group"
    :class="{
      'input-group-focus': focused,
      'has-label': label,
      'has-icon': hasIcon,
    }"
  >
    <slot name="label">
      <label v-if="label"> {{ label }} {{ required ? '*' : '' }} </label>
    </slot>
    <div class="mb-0" :class="{'input-group': hasIcon}">
      <slot name="addonLeft">
        <span v-if="addonLeftIcon" class="input-group-prepend">
          <div class="input-group-text">
            <mdicon :name="addonLeftIcon" />
          </div>
        </span>
      </slot>
      <slot>
        <input
          ref="input"
          :value="value"
          v-bind="$attrs"
          :disabled="disabled"
          v-on="listeners"
          :class="{
            'form-control' : !editiable, 
            'text-light form-control' : editiable , 
            'text-light form-control' : textLight, 
            'text-primary form-control' : textPrimary,
            'text-muted form-control' : textMuted,
            'text-info form-control' : textInfo
          }"
          aria-describedby="addon-right addon-left"

        />
      </slot>
      <slot name="addonRight">
        <span 
          @click="rightIconClick()" 
          v-if="addonRightIcon && !disabled" 
          class="input-group-append">

          <div class="input-group-text">
            <mdicon class="ml-3" :name="!toggleIcon ? addonRightIconToggle : addonRightIcon " size="22"/>
          </div>
        </span>
      </slot>
    </div>

    <slot name="error" v-if="error || $slots.error">
      <label class="error">{{ error }}</label>
    </slot>
    <slot name="helperText"></slot>
  </div>
</template>
<script>
export default {
  inheritAttrs: false,
  name: 'base-input',
  props: {
    textLight: {
      default: false,
      type: Boolean
    },
    textPrimary: {
      default: false,
      type: Boolean
    },
    textInfo: {
      default: false,
      type: Boolean
    },
    textMuted: {
      default: false,
      type: Boolean
    },
    disabled: {
      default: false,
      type: Boolean
    },
    editiable: {
      default: false,
      type: Boolean
    },
    required: Boolean,
    label: {
      type: String,
      description: 'Input label'
    },
    error: {
      type: String,
      description: 'Input error',
      default: ''
    },
    value: {
      type: [String, Number],
      description: 'Input value'
    },
    addonRightIcon: {
      type: String,
      description: 'Input icon on the right'
    },
    addonRightIconToggle : {
      type: String,
      description: 'Input icon on the right alt'
    },
    addonLeftIcon: {
      type: String,
      description: 'Input icon on the left'
    },
  },
  model: {
    prop: 'value',
    event: 'input'
  },
  data() {
    return {
      focused: false,
      touched: false,
      toggleIcon: false,
      setType: this.$attrs['type'],
      altType: 'text'
    };
  },
  computed: {
    hasIcon() {
      return this.hasLeftAddon || this.hasRightAddon
    },
    hasLeftAddon() {
      const { addonLeft } = this.$slots;
      return (
        addonLeft !== undefined ||
        this.addonLeftIcon !== undefined
      );
    },
    hasRightAddon() {
      this.setType = this.$attrs['type'];
      const { addonRight } = this.$slots;
      return (
        addonRight !== undefined ||
        this.addonRightIcon !== undefined
      );
    },
    listeners() {
      return {
        ...this.$listeners,
        input: this.onInput,
        blur: this.onBlur,
        focus: this.onFocus
      };
    }
  },
  methods: {
    rightIconClick(evt){
      this.addonRightIconToggle ? (this.toggleIcon =! this.toggleIcon)  : null;
      this.$emit('right-icon-click')
      this.toggleIcon ? ( this.$attrs['type'] = this.altType) : this.$attrs['type'] = this.setType;

      if(this.editiable){  
        if(this.toggleIcon){
          this.$emit('editing-input-start');
        }else{
          this.$emit('editing-input-end');
        }
      }
      
    },
    onInput(evt) {
      if (!this.touched) {
        this.touched = true;
      }
      this.$emit('input', evt.target.value);
    },
    onFocus(evt) {
      this.focused = true;
      this.$emit('focus', evt)
    },
    onBlur(evt) {
      this.focused = false;
      this.$emit('blur', evt)
    },
    insertMacro(macro, appendBy = '&'){
      const input = this.$refs.input
      const currentString = input.value
      const startPos = input.selectionStart;
      const endPos = input.selectionEnd;
      input.value = currentString.slice(0, startPos) + `${appendBy}${macro}={${macro}} ` + currentString.slice(endPos)
      input.setSelectionRange(input.value.length, input.value.length)
      input.focus();
      return input.value
    },
  }
};
</script>
<style></style>
