import VueRouter from 'vue-router';
//import routes from './routes';
import { children, auth, about } from './routers';
import store from '../auth/store'
import DashboardLayout from 'src/pages/Layout/DashboardLayout.vue';
import NotFound from 'src/pages/GeneralViews/NotFoundPage.vue';


const routes = [
  {
    path: '/',
    component: DashboardLayout,
    redirect: '/home',
    name: 'Dashboard layout',
    children: children,
    meta: {
      requiresAuth: true
    }
  },
  auth,
  about,
  { path: '*', component: NotFound }    
];


//import { publicPath } from '../../vue.config'
let publicPath = process.env.VUE_APP_PUBLIC_PATH
console.log('>>>[INFO] serving path: ' + publicPath)
console.log('>>>[INFO] enviorment: ' + process.env.NODE_ENV)


// configure router
const router = new VueRouter({
  routes, // short for routes: routes
  mode: "history",
  linkActiveClass: 'active',
  base: publicPath,
  scrollBehavior (to, from, savedPosition) {
    if (savedPosition) {
      return savedPosition
    } else {
      return { x: 0, y: 0 }
    }
  }
});


router.beforeEach((to, from, next) => {
  
  if(to.matched.some(record => record.meta.requiresAuth)) {  
    if (store.getters.isLoggedIn) {
      next()
      return
    }
    next('/login')
  } else {
  
    if(to.name == 'UserInfo' && !JSON.parse(store.getters.user.user_confirm_is_developer)){
      //router.push('/home')
      next()
    }else if(to.name != 'UserInfo'){
      next()
    }
    
  }
})

export default router;
