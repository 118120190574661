const View = () => import(/* webpackChunkName: "dashboard" */ '../../pages/Layout/View.vue');
// Calendar
const Calendar = () =>
  import(/* webpackChunkName: "extra" */ 'src/pages/Calendar/CalendarRoute.vue');
// Charts
const Charts = () =>
  import(/* webpackChunkName: "dashboard" */ 'src/pages/Charts.vue');

// Components pages
const Buttons = () =>
  import(/* webpackChunkName: "components" */ 'src/pages/Components/Buttons.vue');
const GridSystem = () =>
  import(/* webpackChunkName: "components" */ 'src/pages/Components/GridSystem.vue');
const Panels = () =>
  import(/* webpackChunkName: "components" */ 'src/pages/Components/Panels.vue');
const SweetAlert = () =>
  import(/* webpackChunkName: "components" */ 'src/pages/Components/SweetAlert.vue');
const Notifications = () =>
  import(/* webpackChunkName: "components" */ 'src/pages/Components/Notifications.vue');
const Icons = () =>
  import(/* webpackChunkName: "components" */ 'src/pages/Components/Icons.vue');
const Typography = () =>
  import(/* webpackChunkName: "components" */ 'src/pages/Components/Typography.vue');
const GoogleMaps = () =>
  import(/* webpackChunkName: "extra" */ 'src/pages/Maps/GoogleMaps.vue');
const FullScreenMap = () =>
  import(/* webpackChunkName: "extra" */ 'src/pages/Maps/FullScreenMap.vue');
const VectorMaps = () =>
  import(/* webpackChunkName: "extra" */ 'src/pages/Maps/VectorMaps.vue');
const UserTable = () =>
  import(/* webpackChunkName: "dashboard" */ 'src/pages/SMS/UserTable.vue');

import Widgets from 'src/pages/Widgets.vue';

export default {
    path: '/components',
    redirect: '/components/buttons',
    component: View,
    name: 'components',
    children: [
      {
        path: 'usertable',
        name: 'Tables',
        components: { default: UserTable }
      },
      
      {
        path: 'buttons',
        name: 'Buttons',
        components: { default: Buttons }
      },
      {
        path: 'grid-system',
        name: 'Grid System',
        components: { default: GridSystem }
      },
      {
        path: 'panels',
        name: 'Panels',
        components: { default: Panels }
      },
      {
        path: 'sweet-alert',
        name: 'Sweet Alert',
        components: { default: SweetAlert }
      },
      {
        path: 'notifications',
        name: 'Notifications',
        components: { default: Notifications }
      },
      {
        path: 'icons',
        name: 'Icons',
        components: { default: Icons }
      },
      {
        path: 'typography',
        name: 'Typography',
        components: { default: Typography }
      },
      {
        path: 'calendar',
        name: 'Calendar',
        components: { default: Calendar }
      },
      {
        path: 'charts',
        name: 'Charts',
        components: { default: Charts }
      },
      {
        path: 'widgets',
        name: 'Widgets',
        components: { default: Widgets }
      },
      {
        path: 'maps/google',
        name: 'GoogleMaps',
        components: { default: GoogleMaps }
      },
      {
        path: 'maps/full-screen',
        name: 'FullScreenMap',
        components: { default: FullScreenMap }
      },
      {
        path: 'maps/vector-map',
        name: 'VectorMaps',
        components: { default: VectorMaps }
      },
    ]
}