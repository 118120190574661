/*!

 =========================================================
 * Message-deck customer front end
 =========================================================

 * Product Page: https://www.creative-tim.com/product/vue-black-dashboard-pro
 * Copyright 2019 Creative Tim (https://www.creative-tim.com)

 * Coded by Creative Tim

 =========================================================

 * The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
 */

import Vue from 'vue';
import VueRouter from 'vue-router';
import RouterPrefetch from 'vue-router-prefetch'
//import { BootstrapVue } from 'bootstrap-vue'
import DashboardPlugin from './plugins/dashboard-plugin';
import store from './auth/store';
import Toast from 'vue-toastification';
//import Axios from 'axios';
import VueAxios from 'vue-axios';
import Axios from './plugins/axios';

import VueCookies from 'vue-cookies';
import mdiVue from 'mdi-vue';
import * as mdijs from '@mdi/js'; //https://bestofvue.com/repo/therufa-mdi-vue-vuejs-icons
//https://materialdesignicons.com/




import App from './App.vue';


//import 'bootstrap/dist/css/bootstrap.css'
//import 'bootstrap-vue/dist/bootstrap-vue.css'
import 'vue-toastification/dist/index.css';
Vue.config.productionTip = false;

Vue.use(VueAxios, Axios);



// router setup
import router from './routes/router';
import i18n from './i18n';
import './registerServiceWorker'
// plugin setup





Vue.use(Toast);
Vue.use(DashboardPlugin);
Vue.use(VueRouter);
Vue.use(RouterPrefetch);
//Vue.use(BootstrapVue);
Vue.use(mdiVue, {
  icons: mdijs
}) 




/* eslint-disable no-new */
new Vue({
  el: '#app',
  render: h => h(App),
  router,
  store,
  i18n
});
