const View = () => import(/* webpackChunkName: "dashboard" */ '../../pages/Layout/View.vue');
const PricingSms = () => import(/* webpackChunkName: "pages" */ 'src/pages/Pages/PricingSms.vue');
const Usage = () => import(/* webpackChunkName: "pages" */ 'src/pages/SMS/Usage.vue');
const PaymentHistory = () => import(/* webpackChunkName: "dashboard" */ 'src/pages/SMS/Finanace/PaymentHistory.vue');
const ApiBillingKeysManagement = () => import(/* webpackChunkName: "tables" */ 'src/pages/SMS/APIs/ApiBillingKeysManagment.vue');
const Payment = () => import('src/pages/SMS/Payment/Payment.vue');


export default {
  path: '/billing',
  component: View,
  redirect: '/billing/pricing',
  name: 'billing',
  children: [
    {
      path: 'pricing',
      name: 'Pricing',
      components: { default: PricingSms }
    },
    {
      path: 'usage',
      name: 'Usage',
      components: { default: Usage }
    },
    {
      path: 'history',
      name: 'Payment history',
      components: { default: PaymentHistory }
    },
    {
      path: 'api-billing-tokens',
      name: 'api-billing-tokens',
      components: { default: ApiBillingKeysManagement }
    },
    {
      path: 'add-funds',
      name: 'Add funds',
      components: { default: Payment }
    }
  ],
}