const Login = () => import(/* webpackChunkName: "pages" */ 'src/pages/Pages/Login.vue');
const Register = () => import(/* webpackChunkName: "pages" */ 'src/pages/Pages/Register.vue');
const Lock = () => import(/* webpackChunkName: "pages" */ 'src/pages/Pages/Lock.vue');
import AuthLayout from 'src/pages/Pages/AuthLayout.vue';

export default {
    path: '/',
    component: AuthLayout,
    name: 'Authentication',
    children: [
      {
        path: '/login',
        name: 'Login',
        meta: {
          title: 'login'
        },
        component: Login
      },
      {
        path: '/signup',
        name: 'Register',
        meta: {
          title: 'Signup'
        },
        component: Register
      },
      {
        path: '/lock',
        name: 'Lock',
        component: Lock
      }
    ]
  }