const View = () => import(/* webpackChunkName: "dashboard" */ '../../pages/Layout/View.vue');
const ApiAccessKeysManagement = () => import(/* webpackChunkName: "tables" */ 'src/pages/SMS/APIs/ApiKeysManagment.vue');
const User = () => import(/* webpackChunkName: "pages" */ 'src/pages/Profile/UserProfile.vue');
const Users = () => import(/* webpackChunkName: "pages" */ 'src/pages/Pages/UsersManagement.vue');

export default {
  path: '/settings',
  component: View,
  redirect: '/settings/account',
  name: 'settings',
  children: [
    {
      path: 'tokens',
      name: 'tokens',
      components: { default: ApiAccessKeysManagement }
    },
    {
      path: 'account',
      name: 'Account details',
      components: { default: User }
    },
    //{
    //  path: 'users',
    //  name: 'User Management',
    //  components: { default: Users }
    //},
    /*
    {
      path: 'account-details',
      name: 'Account details',
      components: { default: Settings }
    },
    */
    {
      path:'profile',
      name:'User profile',
      components: { default: User }
    },
    
  ]
}