<template>
  <li :class="{ 'timeline-inverted': inverted }">
    <slot name="badge">
      <div class="timeline-badge" :class="badgeType">
        <!--
        <i :class="badgeIcon"></i>
        -->
        <mdicon v-if="stateType == 'complete'"  name="check-circle-outline" :size="badgeIconSize" />
        <mdicon v-else name="account-clock-outline" :size="badgeIconSize" />
        
      </div>
    </slot>
    <div class="timeline-panel">
      <div class="timeline-heading">
        <slot name="header"></slot>
      </div>
      <div class="timeline-body" v-if="$slots.content">
        <slot name="content"></slot>
      </div>
      

      <slot name="button" v-if="$slots.button"></slot>
      
      <div v-if="$slots.footer" class="timeline-footer">
        <slot name="footer"></slot>
      </div>

    </div>
  </li>
</template>
<script>
export default {
  name: 'time-line-item',
  props: {
    inverted: Boolean,
    badgeType: {
      type: String,
      default: 'success'
    },
    badgeIcon: {
      type: String,
      default: ''
    },
    badgeIconSize: {
      type: Number,
      default: 32
    },
    stateType: {
      type: String,
      default: ''
    }
  }
};
</script>
<style></style>
