var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"wrapper",class:{ 'nav-open': _vm.$sidebar.showSidebar }},[_c('notifications'),_c('sidebar-fixed-toggle-button'),_c('side-bar',{attrs:{"background-color":_vm.sidebarBackground,"short-title":_vm.$t('sidebar.shortTitle'),"title":_vm.$t('sidebar.title')},scopedSlots:_vm._u([{key:"links",fn:function(props){return [_c('sidebar-item',{attrs:{"link":{
            name: _vm.$t('sidebar.home'),
            //icon: 'tim-icons icon-components', //https://materialdesignicons.com/
            //icon: 'tim-icons',
            icon: 'view-dashboard-outline',
            path: '/home'
          }}}),_c('sidebar-item',{attrs:{"link":{
           name: _vm.$t('sidebar.SMS'), icon: 'message-text-outline' }}},[_c('sidebar-item',{attrs:{"link":{
              name: _vm.$t('sidebar.analytics'),
              icon: 'chart-box-outline',
              path: '/sms/analytics'
            }}}),_c('sidebar-item',{attrs:{"link":{
              name: _vm.$t('sidebar.messageSearch'),
              icon: 'chart-box-outline',
              path: '/sms/search'
            }}})],1),_c('sidebar-item',{attrs:{"link":{ name: _vm.$t('sidebar.delify'), icon: 'send-check-outline', path: '/sms/delify'}}},[_c('span',{attrs:{"slot":"badge"},slot:"badge"},[_c('badge',{attrs:{"type":"success"}},[_c('span',{attrs:{"slot":"badgeContent"},slot:"badgeContent"},[_vm._v("BETA")])])],1)]),_c('sidebar-item',{attrs:{"link":{ 
            name: _vm.$t('sidebar.contacts'), 
            icon: 'contacts-outline', 
            path: '/contacts' }}},[_c('span',{attrs:{"slot":"badge"},slot:"badge"},[_c('badge',{attrs:{"type":"success"}},[_c('span',{attrs:{"slot":"badgeContent"},slot:"badgeContent"},[_vm._v("BETA")])])],1)]),_c('sidebar-item',{attrs:{"link":{ 
            name: _vm.$t('sidebar.campaigns'), 
            icon: 'arrange-send-to-back' 
            }}},[_c('template',{slot:"badge"},[_c('badge',{attrs:{"type":"success"}},[_c('span',{attrs:{"slot":"badgeContent"},slot:"badgeContent"},[_vm._v("BETA")])])],1),_c('sidebar-item',{attrs:{"link":{ name: _vm.$t('sidebar.campaignsList'), path: '/campaigns/list' }}}),_c('sidebar-item',{attrs:{"link":{ name: _vm.$t('sidebar.campaignStats'), path: '/campaigns/analytics' }}}),_c('sidebar-item',{attrs:{"link":{ name: _vm.$t('sidebar.tenDlc'), path: '/campaigns/ten-dlc' }}})],2),_c('sidebar-item',{attrs:{"link":{ 
          name: _vm.$t('sidebar.payment'), 
          icon: 'wallet-outline', 
          path: '/payment' }}},[_c('sidebar-item',{attrs:{"link":{ name: _vm.$t('sidebar.paymentPricing'), path: '/billing/pricing' }}}),_c('sidebar-item',{attrs:{"link":{ name: _vm.$t('sidebar.paymentUsage'), path: '/billing/usage' }}}),_c('sidebar-item',{attrs:{"link":{ name: _vm.$t('sidebar.paymetnHistory'), path: '/billing/history' }}}),_c('sidebar-item',{attrs:{"link":{ name: _vm.$t('sidebar.addFunds'), path: '/billing/add-funds' }}})],1),_c('sidebar-item',{attrs:{"link":{
              name: _vm.$t('sidebar.settings'),
              icon: 'cog-sync-outline'
            }}},[_c('sidebar-item',{attrs:{"link":{
              name: _vm.$t('sidebar.api-access-token'),
              path: '/settings/tokens'
            }}}),_c('sidebar-item',{attrs:{"link":{
              name: _vm.$t('sidebar.account'),
              path: '/settings/account'
            }}})],1),_c('sidebar-item',{attrs:{"link":{
              name: _vm.$t('sidebar.documentation'),
              path: '/documentation',
              icon: 'file-cog-outline'
            }}})]}}])}),_c('sidebar-share',{attrs:{"background-color":_vm.sidebarBackground},on:{"update:backgroundColor":function($event){_vm.sidebarBackground=$event},"update:background-color":function($event){_vm.sidebarBackground=$event}}}),_c('div',{staticClass:"main-panel",attrs:{"data":_vm.sidebarBackground}},[_c('dashboard-navbar'),_c('router-view',{attrs:{"name":"header"}}),_c('div',{class:{ content: !_vm.$route.meta.hideContent },on:{"click":_vm.toggleSidebar}},[_c('div',{staticClass:"row justify-content-center"},[_c('div',{staticClass:"col-lg-12 col-md-12 col-sm-12",staticStyle:{"max-width":"1700px"}},[_c('base-alert',{attrs:{"type":"info","dismissible":"","icon":"tim-icons icon-bell-55"}},[_vm._v(" Some features are in Beta (newly released). If you encounter any issues please contact support@message-deck.com ")])],1)]),_c('br'),(_vm.lowOnFunds)?_c('div',{staticClass:"row justify-content-center"},[_c('div',{staticClass:"col-lg-12 col-md-12 col-sm-12",staticStyle:{"max-width":"1700px"}},[_c('base-alert',{attrs:{"type":"primary","dismissible":"","icon":"tim-icons icon-bell-55"}},[_vm._v(" You are low on funds!    "),_c('router-link',{staticClass:"btn btn-info btn-sm",attrs:{"to":"/billing/add-funds","tag":"button"}},[_vm._v(" Top Up ")])],1)],1),_c('br')]):(_vm.outOfFunds)?_c('div',{staticClass:"row justify-content-center"},[_c('div',{staticClass:"col-lg-12 col-md-12 col-sm-12",staticStyle:{"max-width":"1700px"}},[_c('base-alert',{attrs:{"type":"danger","dismissible":"","icon":"tim-icons icon-bell-55"}},[_vm._v(" You are out of funds!    "),_c('router-link',{staticClass:"btn btn-info btn-sm",attrs:{"to":"/billing/add-funds","tag":"button"}},[_vm._v(" Top Up ")])],1)],1),_c('br')]):_vm._e(),_c('zoom-center-transition',{attrs:{"duration":200,"mode":"out-in"}},[_c('router-view')],1)],1),_c('live-agent'),(_vm.$route.meta.showFooter)?_c('content-footer'):_vm._e()],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }