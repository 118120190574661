<template>
  <div
    class="form-group"
    :class="{
      'input-group-focus': focused,
      'has-label': label,
      'has-icon': hasIcon,
    }"
  >
    <slot name="label">
      <label v-if="label"> {{ label }} {{ required ? '*' : '' }} </label>
    </slot>
    <div class="mb-0" :class="{'input-group': hasIcon}">
      
      <slot>
        <textarea
          ref="textArea"
          :style="`min-height:${height}px`"
          :value="value"
          v-bind="$attrs"
          :disabled="isDisabled || disabled"
          v-on="listeners"
          :class="{'form-control' : !editiable, 'text-light form-control' : editiable , 'text-light form-control' : textLight }"
          aria-describedby="addon-right addon-left"

        />
      </slot>
      
    </div>

    <slot name="error" v-if="error || $slots.error">
      <label class="error">{{ error }}</label>
    </slot>
    <slot name="helperText"></slot>
  </div>
</template>
<script>
export default {
  inheritAttrs: false,
  name: 'base-textarea',
  props: {
    height:{
      type: Number,
      default: 150
    },
    textLight: {
      default: false,
      type: Boolean
    },
    disabled: {
      default: false,
      type: Boolean
    },
    editiable: {
      default: false,
      type: Boolean
    },
    required: Boolean,
    label: {
      type: String,
      description: 'Input label'
    },
    error: {
      type: String,
      description: 'Input error',
      default: ''
    },
    value: {
      type: [String, Number],
      description: 'Input value'
    },
    addonRightIcon: {
      type: String,
      description: 'Input icon on the right'
    },
    addonRightIconToggle : {
      type: String,
      description: 'Input icon on the right alt'
    },
    addonLeftIcon: {
      type: String,
      description: 'Input icon on the left'
    },
  },
  model: {
    prop: 'value',
    event: 'input'
  },
  data() {
    return {
      isDisabled: this.editiable,
      focused: false,
      touched: false,
      toggleIcon: false,
      setType: this.$attrs['type'],
      altType: 'text',
      textArea: null
    };
  },
  computed: {
    hasIcon() {
      return this.hasLeftAddon || this.hasRightAddon
    },
    hasLeftAddon() {
      const { addonLeft } = this.$slots;
      return (
        addonLeft !== undefined ||
        this.addonLeftIcon !== undefined
      );
    },
    hasRightAddon() {
      this.setType = this.$attrs['type'];
      const { addonRight } = this.$slots;
      return (
        addonRight !== undefined ||
        this.addonRightIcon !== undefined
      );
    },
    listeners() {
      return {
        ...this.$listeners,
        input: this.onInput,
        blur: this.onBlur,
        focus: this.onFocus
      };
    }
  },
  methods: {
    insertMacro(macro){
      const textarea = this.$refs.textArea
      const currentString = textarea.value
      const startPos = textarea.selectionStart;
      const endPos = textarea.selectionEnd;

      const afterInsertText = currentString.slice(0, startPos) + `{${macro}} ` + currentString.slice(endPos)
      textarea.setSelectionRange(textarea.value.length, textarea.value.length)
      textarea.focus();

      return afterInsertText

    },
    onInput(evt) {
      if (!this.touched) {
        this.touched = true;
      }
      this.$emit('input', evt.target.value);
    },
    onFocus(evt) {
      this.focused = true;
      this.$emit('focus', evt)
    },
    onBlur(evt) {
      this.focused = false;
      this.$emit('blur', evt)
    }
  }
};
</script>
<style></style>
