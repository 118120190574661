<template>
  <component @click="hideShow" :is="tag" class="badge" :class="`badge-${type}`">
    <slot v-if="showBadgeContent" name="badgeContent"></slot>
    <slot v-if="!showBadgeContent" name="badgeOtherContent"></slot>
  </component>
</template>
<script>
export default {
  name: 'badge',
  methods:{
    hideShow(){
      if(this.hideShowContent){
        this.showBadgeContent = !this.showBadgeContent;
      }
    }
  },
  data:function () {
    return {
      showBadgeContent: true
    };
  },
  props: {
    hideShowContent:{
      type:Boolean
    },
    tag: {
      type: String,
      default: 'span',
      description: 'Badge tag'
    },
    type: {
      type: String,
      default: 'default',
      validator: value => {
        let acceptedValues = [
          'primary',
          'info',
          'success',
          'warning',
          'danger',
          'default'
        ];
        return acceptedValues.indexOf(value) !== -1;
      },
      description: 'Badge type (primary|info|success|warning|danger|default)'
    }
  }
};
</script>
<style></style>
