const View = () => import(/* webpackChunkName: "dashboard" */ '../../pages/Layout/View.vue');
const ComingSoon = () => import(/* webpackChunkName: "pages" */ 'src/pages/Pages/ComingSoon.vue');
const LayoutNew = () => import(/* webpackChunkName: "pages" */ 'src/pages/SMS/Campaigns/New/Layout.vue');
const AnalyticsLayout = () => import(/* webpackChunkName: "pages" */ 'src/pages/SMS/Campaigns/Analytics/Layout.vue');
const ListLayout = () => import(/* webpackChunkName: "pages" */ 'src/pages/SMS/Campaigns/List/Layout.vue');
const TenDlc = () => import(/* webpackChunkName: "pages" */ 'src/pages/SMS/Campaigns/TenDlc.vue');
const LandingPageEditor = () => import(/* webpackChunkName: "pages" */ 'src/pages/Pages/LandingPageEditor.vue');

export default {
    path: '/campaigns',
    redirect: '/campaigns/new',
    component: View,
    name: 'campaigns',
    children: [
      {
        path: 'analytics',
        name: 'Campaign analytics',
        components: { default: AnalyticsLayout }
      },
      {
        path:'new',
        name:'New Campaign',
        components: { default: LayoutNew }
      },
      {
        path:'list',
        name:'Campaigns',
        components: { default: ListLayout }
      },
      {
        path:'ten-dlc',
        name:'10 DLC',
        components: { default: TenDlc }
      },
      {
        path:'landing-page-editor',
        name:'Landing Page Editor',
        components: { default: LandingPageEditor }
      }
    ]
}