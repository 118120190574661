import Vue from 'vue'
import Vuex from 'vuex'
import createPersistedState from 'vuex-persistedstate'
import axios from 'axios'

Vue.use(Vuex)

const local_auth_base_url = process.env.VUE_APP_LOCAL_AUTH_ENDPOINT

export default new Vuex.Store({
  state: {
    account: {},
    status: '',
    token: localStorage.getItem('token') || '',
    user : {}
  },
  mutations: {
    account_status(state, account){
      state.account = account
    },
    auth_request(state){
      state.status = 'loading'
    },
    auth_success(state, data){
      state.status = 'success'
      state.token = data.token
      state.user = data.user
      
    },
    auth_error(state){
      state.status = 'error'
    },
    auth_logout(state){
      state.status = null
      state.token = null
      state.user = null
    },
    user_type(state, user){
      state.user = { ...user }
    }
  },
  actions: {
    account({commit}, account){
      commit('account_status', account)
    },
    loginFBGGL({commit}, data){
      commit('auth_request')
      return new Promise((resolve, reject) => {
        let token = data.token
        let user = data.user
        //localStorage.setItem('token', token)
        axios.defaults.headers.common['Authorization'] = token
        
        commit('auth_success', {
          token:token,
          user:user
        })
        resolve()
      })
    },
    confirm({commit}, confirmation_token_object){
      return new Promise((resolve, reject) => {
        commit('auth_request')
        
        //axios({url: '/auth/confirm', data: confirmation_token_object, method: 'POST' })
        axios({url: local_auth_base_url + '/confirm', data: confirmation_token_object, method: 'POST' })
        
        .then(resp => {
          
          if(!resp.data.user.user_confirm_terms){
            commit('auth_error')
            localStorage.removeItem('token')
            reject({
              user: resp.data.user,
              code: 88,
              message: 'Please confirm our terms and conditions'
            })
            return
          }else if(!resp.data.user.user_confirm_is_developer){
            const token = resp.data.token
            const user = resp.data.user
            axios.defaults.headers.common['Authorization'] = token
            
            commit('auth_success', {
              token:token,
              user:user
            })

            reject({
              user: resp.data.user,
              code: 77
            })
            
          }else{
            const token = resp.data.token
            const user = resp.data.user
            //localStorage.setItem('token', token)
            
            axios.defaults.headers.common['Authorization'] = token
            commit('auth_success', {
              token:token,
              user:user
            })
            resolve()
          }
        })
        .catch(err => {
          commit('auth_error')
          localStorage.removeItem('token')
          reject()
        })
      })
    },
    login({commit}, user){
      
        return new Promise((resolve, reject) => {
          commit('auth_request')
          
          //axios({url: '/auth/login', data: user, method: 'POST' })
          axios({url: local_auth_base_url + '/login', data: user, method: 'POST' })
          .then(resp => {
            
            if(!resp.data.user.user_confirm_terms){
              commit('auth_error')
              localStorage.removeItem('token')
              reject({
                user: resp.data.user,
                code: 88,
                message: 'Please confirm our terms and conditions'
              })
            }else if(!resp.data.user.user_confirm_is_developer){
              const token = resp.data.token
              const user = resp.data.user
              axios.defaults.headers.common['Authorization'] = token
              commit('auth_success', {
                token:token,
                user:user
              })

              reject({
                user: resp.data.user,
                code: 77
              })
            }else{
              const token = resp.data.token
              const user = resp.data.user
              axios.defaults.headers.common['Authorization'] = token
              commit('auth_success', {
                token:token,
                user:user
              })
              
              resolve(resp.data.user)
            }
            
            
            
            
          })
          .catch((err) => {            
            commit('auth_error')
            localStorage.removeItem('token')
            reject()
          })
        })
    },
    register({commit}, user){
      
        return new Promise((resolve, reject) => {
          commit('auth_request')
          //axios({url: '/auth/register', data: user, method: 'POST' })
          axios({url: local_auth_base_url + '/register', data: user, method: 'POST' })
          .then(resp => {
             //console.log(resp.data)
             const token = resp.data.token
             const user = resp.data.user
             //localStorage.setItem('token', token)
             
             axios.defaults.headers.common['Authorization'] = token
             commit('auth_success', {
               token:token,
               user:user
             })
             resolve()
          })
          .catch(err => {
            commit('auth_error', err)
            localStorage.removeItem('token')
            reject(err)
          })
        })
    },
    logout({commit}){
      return new Promise((resolve, reject) => {
        commit('auth_logout')
        localStorage.removeItem('token')
        localStorage.removeItem('user')
        delete axios.defaults.headers.common['Authorization']
        resolve()
      })
    },
    developer({commit}, user){
      return new Promise((resolve, reject) => {
        commit('user_type', user)
        resolve()
      })
    },
    switchUserMode({commit}, user){
      return new Promise((resolve, reject) => {
        commit('user_type',user)
        resolve()
      })
    }
  },
  getters : {
    isLoggedIn: state => !!state.token,
    authStatus: state => state.status,
    user: state => state.user,
    iseDeveloper: state => state.developer,
    account: state => state.account
  },
  plugins: [
    createPersistedState({
      key: 'msdckadmin'
    })
  ]
})

