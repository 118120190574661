
const View = () => import(/* webpackChunkName: "dashboard" */ '../../pages/Layout/View.vue');
const SmsAnalytics = () => import(/* webpackChunkName: "dashboard" */ '../../pages/SMS/Analytics/AnalyticsLayout.vue');
const Analytics = () => import(/* webpackChunkName: "dashboard" */ '../../pages/SMS/Analytics.vue');
const MessageSearch = () => import(/* webpackChunkName: "tables" */ '../../pages/SMS/MessageSearch/SmsSearchLayout.vue');
const Delify = () => import(/* webpackChunkName: "dashboard" */ '../../pages/SMS/Delify/Layout.vue');

export default {
  
    path: '/sms',
    redirect: '/sms/analytics',
    component: View,
    name: 'sms',    
    children: [
      {
        path: 'analytics',
        name: 'SMS Analytics',
        components: { default: SmsAnalytics }
      },
      {
        path: 'old-analytics',
        name: 'Analytics',
        components: { default: Analytics }
      },
      {
        path: 'search',
        name: 'search',
        components: { default: MessageSearch }
      },
      {
        path: 'delify',
        name: 'delify',
        components: { default: Delify }
      }
    ]
  }