import Vue from 'vue';
import axios from 'axios';
import store from '../auth/store';

var storage = JSON.parse(localStorage.getItem('msdckadmin'))

if (storage && storage.token) axios.defaults.headers.common['Authorization'] = storage.token

axios.interceptors.request.use(
    (request) => {
        return request;
    }
);


/**
 * to do: implement refresh token
 */



axios.interceptors.response.use(
    (response) => {
        return response
    },
    (error) => {
        //console.log(error)
        //Vue.$toast.error('Error: ' + error.response.status + " " + error.response.data);
        
        if(error.response.status == '401'){
            store.dispatch('logout')
            return Promise.reject(error.message);    
        }

        Vue.$toast.error(error.response.data);
        // Disabled this 31/10/23
        //return Promise.reject(error.message);

    }
);

export default axios;