<template>
  <div>
    
    <live-agent-chat-box :showChat="showChat" @showChat="e => showChat=e.value"></live-agent-chat-box>
    <base-button 
        class="live-agent-button"
        @click="showChat=!showChat" lg type="primary" round icon>
      <span id="chat-icons">
       <mdicon v-if="!showChat" style="display:block !important" name="face-agent" size="64" />   
       <mdicon v-if="showChat" style="display:block !important" name="close" size="44" />   
      </span>
     </base-button>
  </div>
</template>

<script>
import LiveAgentChatBox from './LiveAgentChatBox.vue'
export default {
    components:{
        LiveAgentChatBox
    },
    data(){
        return {
            showChat: false
        }
    }
}
</script>

<style>

.live-agent-button{
    height: 5rem !important;
    width: 5rem !important;
    border-radius: 40px;
    position: fixed !important;
    right: 30px;
    bottom: 30px;
}

</style>